if ("NodeList" in window && !NodeList.prototype.forEach) {
  console.info("polyfill for IE11");
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (let i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}

function getEventsWeek( date ){
  fetch( `${site_url}ajax/functions.php?operation=eventsWeek&date=${date}`)
	.then((response) => response.json())
	.then((data) => {
    document.querySelector('.js-eventsCalendarWeek-container').innerHTML = data.html;
	})
}

let intervalID;

(function () {
  //ChangeForm
  startInterval();
  // Remove empty <p> tags created by tinymce
  const paragraph = document.querySelectorAll("p") || [];
  paragraph.forEach(
    (e) => e.innerHTML.trim() === "" && e.parentNode.removeChild(e)
  );
  /*
    ? Open Scroll Animation If The Url Has '#'
  */
    window.addEventListener("load", (event) => {
      let sectionId = location.hash.substring(1);
      let sectionFromUrl = document.getElementById(sectionId);
      if( sectionFromUrl ){
          sectionFromUrl.scrollIntoView({ 
              behavior: "smooth", 
              block: "center"
          });
          /*
              * The properties used for the parameter 'block' it's for indicate where the section found should be respect the screen 'start, center, end'
          */
      };
  });
  /*
    ? Close Scroll Animation If The Url Has '#'
  */

  let TitleIntro = document.getElementById("title_intro");
  let TextIntro = document.getElementById("text_intro");

  if (TextIntro && TitleIntro) {
    TitleIntro = TitleIntro.innerHTML;
    if (TextIntro.getElementsByTagName("p")[0]) {
      TextIntro.getElementsByTagName("p")[0].innerHTML =
        '<span class="title-copy">' +
        TitleIntro +
        "</span>" +
        TextIntro.getElementsByTagName("p")[0].innerHTML;
    } else {
      TextIntro.innerHTML =
        '<p><span class="title-copy">' +
        TitleIntro +
        "</span>" +
        TextIntro.innerHTML +
        "</p>";
    }
  }

  $(".sym-slides").slick({
    accessibility: true,
  });

  $(".gallery-slider").slick({
    arrows: true,
    dots: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 500,
    fade: true,
    accessibility: true,

    prevArrow:
      '<button class="slick-prev slick-arrow" aria-label="Back" type="button" style="display: block;">Back</button>',
    nextArrow:
      '<button class="slick-next slick-arrow" aria-label="Next" type="button" style="display: block;">Next</button>',
  });

  $(".images-slides").slick({
    arrows: true,
    dots: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 500,
    fade: true,
    prevArrow:
      '<button class="slick-prev slick-arrow" aria-label="Prev" type="button" style="display: block;">Prev</button>',
    nextArrow:
      '<button class="slick-next slick-arrow" aria-label="Next" type="button" style="display: block;">Next</button>',
    accessibility: true,
  });

  $(".line-points-slider").slick({
    arrows: true,
    dots: false,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    accessibility: true,
    prevArrow:
      '<button class="slick-prev slick-arrow" aria-label="Back" type="button" style="display: block;">Back</button>',
    nextArrow:
      '<button class="slick-next slick-arrow" aria-label="Next" type="button" style="display: block;">Next</button>',
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  if(document.querySelector(".reviews-slides")){
    $(".reviews-slides").slick({
      arrows: true,
      dots: true,
      infinite: true,
      autoplay: false,
      autoplaySpeed: 4000,
      speed: 700,
      fade: true,
      accessibility: true,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            dots: false,
          },
        }
      ],
    });
  }
  
  $(".video-inline-slider").slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: true,
    dots: false,
    prevArrow:
      '<button class="slick-prev slick-arrow" aria-label="Back" type="button" style="display: block;">Back</button>',
    nextArrow:
      '<button class="slick-next slick-arrow" aria-label="Next" type="button" style="display: block;">Next</button>',
    responsive: [
      {
        breakpoint: 747,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  });

  $(".slider-slide").slick({
    arrows: false,
    dots: true,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 4000,
    speed: 700,
    fade: true,
    accessibility: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          adaptiveHeight: true,
        },
      },
    ],
  });

  $(".slider-bg-content").slick({
    arrows: true,
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 700,
    fade: true,
    accessibility: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          adaptiveHeight: true,
        },
      },
    ],
  });

  $(".slider-overlap-content").slick({
    arrows: true,
    dots: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 6000,
    speed: 700,
    fade: true,
    accessibility: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          adaptiveHeight: true,
        },
      },
    ],
  });

  $(".slider-featured-slide").slick({
    arrows: true,
    dots: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 4000,
    speed: 700,
    fade: true,
    accessibility: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          adaptiveHeight: true,
        },
      },
    ],
  });

  let initialSlideEventWeek = $(".js-sliderEventWeek").attr('data-current_slide');
  initialSlideEventWeek = parseInt(initialSlideEventWeek);
  $(".js-sliderEventWeek").slick({
    arrows: true,
    dots: false,
    infinite: false,
    autoplay: false,
    fade: false,
    accessibility: true,
    initialSlide: initialSlideEventWeek
  });
  $('.js-sliderEventWeek').on('afterChange', function(event, slick, currentSlide, nextSlide){
    let elementActive = document.querySelector(`.eventWeek_dateSlide-month[data-slide_counter="${currentSlide}"]`)
    let date = elementActive.dataset.month;
    getEventsWeek(date);
  });

  if( document.querySelector('.js-eachShortcodeEventCalendarWeek') ){
    let mesActive = document.querySelector('.eventWeek_dateSlide-month.slick-active').dataset.month;
    getEventsWeek(mesActive);

    let todayButton = document.querySelector(`.js-todayButton-eventsWeek`);
    todayButton.addEventListener( 'click', () => {
      $('.js-sliderEventWeek').slick('slickGoTo', initialSlideEventWeek)
    } );
  }

  let ScrollDown = document.getElementsByClassName("js-scrolldown");
  if (ScrollDown.length > 0) {
    ScrollDown[0].addEventListener("click", function () {
      animateto("intro");
    });
  }

  //FAQ
  setTimeout(function () {
    $(".tab-label").each(function () {
      let thisHeightTap = $(this).height() + 40;
      let thisHeight = $(this).next(".tab-content").height() + thisHeightTap;
      $(this)
        .parent()
        .animate({ height: thisHeightTap + "px" }, 400);
      if ($(this).hasClass("open")) {
        $(this)
          .parent()
          .animate({ height: thisHeight + "px" }, 400);
      }
    });
    $(".learn-more-btn").append('<div class="arrow"></div>');
  }, 400);

  $(".tab-label").click(function () {
    let currentclick = $(this);
    let thisHeightTap = $(this).height() + 40;

    let slideIndex = $(this).data("artwork");
    $(".accordion-slide").slick("slickGoTo", parseInt(slideIndex));

    if (!currentclick.hasClass("open")) {
      $(".tab-label").each(function () {
        if ($(this) !== currentclick) {
          if ($(this).hasClass("open")) {
            let thisHeight = $(this).height() + 40;
            $(this).removeClass("open");
            $(this)
              .parent()
              .animate({ height: thisHeight + "px" }, 400);
          }
        }
      });
      currentclick.addClass("open");
      let thisHeight = $(this).next(".tab-content").height() + thisHeightTap;
      $(this)
        .parent()
        .animate({ height: thisHeight + "px" }, 400);
    } else {
      currentclick.removeClass("open");
      currentclick.parent().animate({ height: thisHeightTap + "px" }, 400);
    }
  });

  $("[data-fancybox]").fancybox({
    loop: true,
    backFocus: false,
    autoFocus: false,
  });

  $(
    "a[href^='#']:not(:where(a[data-fancybox], a[data-filter], a.accordion-toggle, a#js_btn_book))"
  ).click(function (e) {
    e.preventDefault();
    let strAncla = $(this).prop("hash").substr(1);
    animateto(strAncla);
  });

  let id_a = "id" + Math.random().toString(16).slice(2);
  $("a[href$='.pdf']").each(function () {
    let valor = $(this).attr("href");
    $(this).attr("href", valor + "?" + id_a);
  });

  let screenHeight = $(window).height();
  $("#home .top-img figure, #home .top-img video").css("height", screenHeight);

  $(window).resize(function () {
    screenHeight = $(window).height();
    $("#home .top-img figure, #home .top-img video").css(
      "height",
      screenHeight
    );
  });

  $("input[name*=select_destination]").eq(0).attr("checked", "checked");

  $(".btn, .signup-btn, .btn-card").each(function () {
    let htmlString = $(this).text();
    $(this).html(
      "<span>" +
        htmlString +
        '</span><span class="arrow-btn"><svg xmlns="http://www.w3.org/2000/svg" width="22.2333" height="11.6376" viewBox="0 0 22.2333 11.6376"><path d="M16.5734,0l-.7475,.7685,4.3839,4.5069H0v1.0873H20.2098l-4.3839,4.5063,.7475,.7686,5.6599-5.8188L16.5734,0Z"/></svg></span>'
    );
  });

  // Dining Pop Ups
  let CURRENT_URL = window.location.href;
  if (CURRENT_URL.indexOf("#") > 0) {
    // The url has it
    let CURRENT_ANCHOR = CURRENT_URL.split("#")[1];
    $("[data-fancybox]").each(function () {
      if ($(this).attr("href").indexOf(CURRENT_ANCHOR) > -1) {
        let CURRENT_HREF = $(this).attr("href");
        $.fancybox.open({
          src: CURRENT_HREF,
          type: "inline",
          opts: {
            afterShow: function (instance, current) {
              console.info("done!");
            },
          },
        });
      }
    });
  }
})();

function animateto(target) {
  var gotoTarget = offset(document.getElementById(target)).top - 130;
  var from = window.pageYOffset;
  var to = gotoTarget;
  var duration = 1300;
  var start = new Date().getTime();

  //time interval
  var timer = setInterval(function () {
    var time = new Date().getTime() - start;

    if (from <= to) {
      var y = easeInOutQuart(time, from, to - from, duration);
    } else {
      var y = easeInOutQuart(time, from, -(from - to), duration);
    }
    window.scrollTo(0, y);
    if (time >= duration) clearInterval(timer);
  }, 1000 / 60);

  window.scrollTo(0, from);
}
//ease movement
function easeInOutQuart(t, b, c, d) {
  if ((t /= d / 2) < 1) return (c / 2) * t * t * t * t + b;
  return (-c / 2) * ((t -= 2) * t * t * t - 2) + b;
}
function offset(el) {
  var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
}

// Function to start setInterval call
function startInterval() {
  intervalID = setInterval(changeFieldsinForm, 1500);
}

// Function to stop setInterval call
function stopInterval() {
  clearInterval(intervalID);
}

//On load Function
function changeFieldsinForm() {
  const path = window.location.pathname;
  if (path.indexOf("/sparty") > -1) {
    const fields = document.querySelectorAll(
      ".form-module__group input,.form-module__group select"
    );
    fields.forEach((item) => {
      let id =
          item.type !== "checkbox"
            ? item.parentNode.parentNode.firstChild.innerText.split(" ")[1]
            : item.parentNode.parentNode.parentNode.firstChild.innerText.split(
                " "
              )[1],
        itemName = item.name.replace(/^\w\s|_|\[\]/gi, "");
      item.name =
        item.type === "checkbox"
          ? itemName + "_" + id + "[]"
          : itemName + "_" + id;
    });
    stopInterval();
  }
}
