self.fetch||(self.fetch=function(e,n){return n=n||{},new Promise(function(t,s){var r=new XMLHttpRequest,o=[],u=[],i={},a=function(){return{ok:2==(r.status/100|0),statusText:r.statusText,status:r.status,url:r.responseURL,text:function(){return Promise.resolve(r.responseText)},json:function(){return Promise.resolve(JSON.parse(r.responseText))},blob:function(){return Promise.resolve(new Blob([r.response]))},clone:a,headers:{keys:function(){return o},entries:function(){return u},get:function(e){return i[e.toLowerCase()]},has:function(e){return e.toLowerCase()in i}}}};for(var c in r.open(n.method||"get",e,!0),r.onload=function(){r.getAllResponseHeaders().replace(/^(.*?):[^\S\n]*([\s\S]*?)$/gm,function(e,n,t){o.push(n=n.toLowerCase()),u.push([n,t]),i[n]=i[n]?i[n]+","+t:t}),t(a())},r.onerror=s,r.withCredentials="include"==n.credentials,n.headers)r.setRequestHeader(c,n.headers[c]);r.send(n.body||null)})});

if ('NodeList' in window && !NodeList.prototype.forEach) {
    console.info('polyfill for IE11');
    NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window;
        for (let i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}

(function (){
    let MAP;
    let HOTEL_PIN;
    let iconDefault;
    let iconHover;

    if( document.querySelector("body#attractions") !== null ) {
        iconDefault = marker_url;
        iconHover = marker_hover_url;

        // get height of window
        let windowHeight = window.innerHeight;

        // get main container, attraction container and map container
        //let mainContainer = document.querySelector('.attractions-content');
        let targetMap = document.querySelector(".attractions-map");
        let targetAtractions = document.querySelector(".attractions-content__left");


        //SETTINGS
        let categoriesData = [],
            GoogleMapsMarkers = [],
            $GoogleMaps = [];

        const CATEGORY_SELECTOR = document.querySelector("select#attraction-category"),
            DISTANCE_SELECTOR = document.querySelector("select#attraction-distance"),
            GOOGLE_MAPS_WRAPPER = document.querySelector("#attractions-map"),
            ATTRACTIONS_CONTENT = document.querySelector(".attractions-content"),
            ATTRACTIONS_WRAPPER = document.querySelector("#attractions-wrapper"),
            LIGHTBOX__FULL_WRAPPER = document.querySelector("#attraction-lightbox"),
            LIGHTBOX__CLOSE_BUTTON = "js-close-lightbox",
            $LIGHTBOX__CLOSE_BUTTON = document.querySelector(`#${LIGHTBOX__CLOSE_BUTTON}`),
            LIGHTBOX_WRAPPER = document.querySelector("#attraction-lightbox__content");



        const attractions_header = document.querySelector('body#attractions header');
        if(attractions_header){
            let header_height = attractions_header.offsetHeight;
            let header_top = attractions_header.offsetTop;

            targetMap.style.top = header_height+'px';
            targetMap.style.height = 'calc(100vh - '+header_height+'px)';

        }


        /*
         * HELPERS
         ********************************************************************************/
        /**
         * SELECTORS
         */
        function PopulateSelectors(el, data, key, selector = "Select an Option"){

            var allKeys = Object.keys(data).map(function(e) {
                return data[e][key];
            });
            var keys = allKeys.filter( (item, index) => allKeys.indexOf(item) >= index );

            //SORT ALPHABETICALLY
            keys.sort( (a, b) => {
                if(a < b) return -1;
                if(a > b) return 1;
                return 0;
            });

            let markup = `<option value=''>${selector}</option>`;
            keys.forEach( el => markup += el !== "" ? `<option value='${el}'>${el}</option>` : "");
            el.innerHTML = markup;
        }

        /**
         * MARKUP
         */
        function GetAttractionsMarkup(attractions){
            let markup = ``;
            attractions.forEach( attraction => markup += getAttractionMarkUp(attraction) );

            ATTRACTIONS_WRAPPER.innerHTML = markup;
            let getAllAttractionList = document.querySelectorAll("article[data-attraction]");
            getAllAttractionList.forEach( element => element .addEventListener("click", event => OpenLightbox(element) ) );
        }

        function hoverPinHotel(pin, map){

            let nameMarkerOnHover = '<div class="nameMarker"><img src="'+CLIENT_IMAGE+'" loading="lazy"/><br><b>' + CLIENT_NAME + '</b><br>' + SYM_ADDRESS1 + '</div>';

            let infowindow = new google.maps.InfoWindow({
                content: nameMarkerOnHover
            });

            pin.addListener('mouseover', function () {
                infowindow.open(map, pin);
                // pin.setIcon(iconHover);
            })

            pin.addListener('mouseout', function () {
                infowindow.close(map, pin);
                //pin.setIcon(iconDefault);
            })
        }

        function matchAttractionWithMarkers() {

            let tmpGetAttractionFromList = document.querySelectorAll("article[data-attraction]");

            let getAttractionFromList = {};
            tmpGetAttractionFromList.forEach(function (itemAttr) {
                let titleAttrBox = itemAttr.getAttribute('data-name');
                getAttractionFromList[titleAttrBox] = itemAttr;
            });

            GoogleMapsMarkers.forEach(function (itemMarker) {

                let titleMarker = itemMarker.title;
                let nameMarkersOnHover;
                let infowindows;

                if( getAttractionFromList.hasOwnProperty(titleMarker) ) {
                    let attractionBox = getAttractionFromList[titleMarker];

                    var ua = navigator.userAgent, 
                    tablet = /Tablet|iPad/i.test(ua);

                    if(!tablet){
                        attractionBox.addEventListener('mouseover', function () {
                            infowindows = getAttractionHoverMurkup(this, itemMarker, nameMarkersOnHover, infowindows);
                        })
                        attractionBox.addEventListener('mouseout', function () {
                            itemMarker.setIcon(iconDefault);
                            infowindows.close(MAP, itemMarker);
                        })
                    }    

                }

            })
        }

        function getAttractionHoverMurkup(_this, itemMarker, nameMarkersOnHover, infowindows){

            var image_markup = "";
            if( _this.querySelector( "img" ).getAttribute( "src" ) )
                image_markup = `<img src="`+ _this.querySelector( "img" ).getAttribute( "src" ) + `" alt="" loading="lazy">`

            nameMarkersOnHover = '' +
                '<article data-attraction="${id}" class="attraction-hover" data-name="${name}">' +
                '<div class="attraction-hover__wrapper">' +
                '<figure class="attraction-hover__image">' + image_markup + '</figure>' +
                '<div class="attraction-hover__copy">'+ $( _this ).find( "h2" ).html() +'</div>' +
                '</div>' +
                '</article>';
            infowindows = new google.maps.InfoWindow({
                content: nameMarkersOnHover
            });

            itemMarker.setIcon(iconHover);
            infowindows.open(MAP, itemMarker);
            var latLng = itemMarker.getPosition();
            MAP.setCenter(latLng);

            return infowindows;
        }

        function getAttractionMarkUp(attraction) {
            const {id, name, thumb, description, address, distance, walking, biking, driving, subway,
                    label_learn_more, label_minutes_walking, label_minutes_biking, url,
                    label_minutes_driving,label_minutes_subway,label_miles,label_ft,label_from,label_visit_website
                } = attraction;

            let getNameUrlImagethumb = thumb[0].full_size.split('/');
            let checkAmountUrl = getNameUrlImagethumb[getNameUrlImagethumb.length - 1];
            let imageAttraction = checkAmountUrl.length > 11 ?  `${thumb[0].full_size}` : '';

            let image_markup = "";
            if( imageAttraction )
                image_markup = `<img src="${imageAttraction}" alt="${thumb[0].alt !== 'null' ? thumb[0].alt : 'placeholder image'}" loading="lazy">`;

            let iconMiles = '<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 20 20"><path fill-rule="evenodd" d="M10,0.5c-3.3,0-6,3-6,6.7c0,5,6,12.4,6,12.4s6-7.4,6-12.4C16,3.5,13.3,0.5,10,0.5z M10,10.2c-1.7,0-3-1.4-3-3.1c0-1.7,1.4-3,3.1-3s3,1.4,3,3.1C13.1,8.8,11.7,10.2,10,10.2z"/></svg>';
            let milesInfo = `<span>${distance} ${(distance > 100)?label_ft:label_miles} ${label_from} ${hotel_name}</span>`;

            let iconWalking = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M9.797 8.906l-2.813 14.109h2.109l1.828-8.016 2.063 2.016v6h2.016v-7.5l-2.109-2.016 0.609-3c1.313 1.5 3.281 2.484 5.484 2.484v-1.969c-1.922 0-3.469-1.031-4.266-2.438l-1.031-1.594c-0.422-0.609-0.984-0.984-1.688-0.984-0.281 0-0.516 0.094-0.797 0.094l-5.203 2.203v4.688h2.016v-3.375l1.781-0.703zM13.5 5.484c-1.078 0-2.016-0.891-2.016-1.969s0.938-2.016 2.016-2.016 2.016 0.938 2.016 2.016-0.938 1.969-2.016 1.969z"/></svg>';
            let walkingInfo = `<span>${walking.replace('mins','').replace('min','')} ${label_minutes_walking}</span>`;

            let iconBiking = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.984 20.484c1.922 0 3.516-1.547 3.516-3.469s-1.594-3.516-3.516-3.516-3.469 1.594-3.469 3.516 1.547 3.469 3.469 3.469zM18.984 12c2.813 0 5.016 2.203 5.016 5.016s-2.203 4.969-5.016 4.969-4.969-2.156-4.969-4.969 2.156-5.016 4.969-5.016zM10.781 10.5l2.203 2.297v6.188h-1.969v-4.969l-3.234-2.813c-0.422-0.281-0.563-0.797-0.563-1.406 0-0.516 0.141-0.984 0.563-1.406l2.813-2.813c0.281-0.422 0.797-0.563 1.406-0.563s1.078 0.141 1.594 0.563l1.922 1.922c0.891 0.891 2.063 1.5 3.563 1.5v2.016c-2.109 0-3.75-0.797-5.063-2.109l-0.797-0.797zM5.016 20.484c1.922 0 3.469-1.547 3.469-3.469s-1.547-3.516-3.469-3.516-3.516 1.594-3.516 3.516 1.594 3.469 3.516 3.469zM5.016 12c2.813 0 4.969 2.203 4.969 5.016s-2.156 4.969-4.969 4.969-5.016-2.156-5.016-4.969 2.203-5.016 5.016-5.016zM15.516 5.484c-1.078 0-2.016-0.891-2.016-1.969s0.938-2.016 2.016-2.016 1.969 0.938 1.969 2.016-0.891 1.969-1.969 1.969z"/></svg>';
            let bikingInfo = `<span>${biking.replace('mins','')} ${label_minutes_biking}</span>`;

            let iconDriving = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5.016 11.016h13.969l-1.5-4.5h-10.969zM17.484 15.984c0.844 0 1.5-0.656 1.5-1.5s-0.656-1.5-1.5-1.5-1.5 0.656-1.5 1.5 0.656 1.5 1.5 1.5zM6.516 15.984c0.844 0 1.5-0.656 1.5-1.5s-0.656-1.5-1.5-1.5-1.5 0.656-1.5 1.5 0.656 1.5 1.5 1.5zM18.938 6l2.063 6v8.016c0 0.563-0.422 0.984-0.984 0.984h-1.031c-0.563 0-0.984-0.422-0.984-0.984v-1.031h-12v1.031c0 0.563-0.422 0.984-0.984 0.984h-1.031c-0.563 0-0.984-0.422-0.984-0.984v-8.016l2.063-6c0.188-0.609 0.797-0.984 1.453-0.984h10.969c0.656 0 1.266 0.375 1.453 0.984z"/></svg>';
            let drivingInfo = `<span>${driving.replace('mins','').replace('min','')} ${label_minutes_driving}</span>`;

            let iconSubway = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 400 400" xml:space="preserve"><g><g><path d="M202.667,0C108.373,0,32,10.667,32,85.333V288c0,41.28,33.493,74.667,74.667,74.667l-32,32v10.667h256v-10.667l-32-32 c41.28,0,74.667-33.387,74.667-74.667V85.333C373.333,10.667,296.96,0,202.667,0z M106.667,320c-17.707,0-32-14.293-32-32 s14.293-32,32-32s32,14.293,32,32S124.373,320,106.667,320z M181.333,192H74.667V85.333h106.667V192z M298.667,320 c-17.707,0-32-14.293-32-32s14.293-32,32-32s32,14.293,32,32S316.373,320,298.667,320z M330.667,192H224V85.333h106.667V192z"></path></g></g></svg>';
            let subwayInfo = `<span>${subway.replace('mins','').replace('min','')} ${label_minutes_subway}</span>`;
            let link = url.indexOf('http')<0?site_url+url:url;

            let markup = `<article data-attraction="${id}" class="attraction" data-name="${name}">
                            <div class="attraction__wrapper">
                                <figure class="attraction__image">
                                    ${image_markup}
                                </figure>
                                <div class="attraction__info">
                                    <h2>${name}</h2>
                                    <p>${address}</p>
                                    <p class="description">${description}</p>
                                
                                    ${distance.length > 0 || walking.length > 0 || biking.length > 0 || driving.length > 0 || subway.length > 0  ? '<ul class="additional-info">' : ''}
                                        ${distance.length > 0 ? '<li>' + iconMiles + ' ' + milesInfo + '</li>' : ''}
                                        ${walking.length > 0 ? '<li>' + iconWalking + ' ' + walkingInfo + '</li>' : ''}
                                        ${biking.length > 0 ? '<li>' + iconBiking + ' ' + bikingInfo + '</li>' : ''}
                                        ${driving.length > 0 ? '<li>' + iconDriving + ' ' + drivingInfo + '</li>' : ''}
                                        ${subway.length > 0 ? '<li>' + iconSubway + ' ' + subwayInfo + '</li>' : ''}
                                    ${distance.length > 0 || walking.length > 0 || biking.length > 0 || driving.length > 0 || subway.length > 0 ? '</ul>' : ''}
                                    ${url && '<a class="pin-website" href="'+link+'" target="_blank">'+label_visit_website+'</a>'}  
                                    <a class="learn-more-btn btn"><span>${label_learn_more}</span><div class="line"></div></a>
                                </div>
                            </div>
                        </article>`;

            return markup;
        }

        function getAttractionMarkUpHover(attraction) {
            const {id, name, thumb, description, address, distance, walking, biking, driving} = attraction;

            let getNameUrlImagethumb = thumb[0].thumbnail.split('/');
            let checkAmountUrl = getNameUrlImagethumb[getNameUrlImagethumb.length - 1];
            let imageAttraction = checkAmountUrl.length > 11 ?  `${thumb[0].thumbnail}` : '';

            let image_markup = "";
            if( imageAttraction )
                image_markup = `<img src="${imageAttraction}" alt="${thumb[0].alt !== 'null' ? thumb[0].alt : 'placeholder image'}" loading="lazy">`

            let markup = `<article data-attraction="${id}" class="attraction-hover" data-name="${name}">
                            <div class="attraction-hover__wrapper">
                                <figure class="attraction-hover__image">
                                    ${image_markup}
                                </figure>
                                <div class="attraction-hover__copy">${name}</div>
                            </div>
                        </article>`;

            return markup;
        }

        //this will appear when the filter does not find any content
        let noContentfilter = `<div class='no-filter-content'>
                                    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 32 32"> <path d="M16 3c-3.472 0-6.737 1.352-9.192 3.808s-3.808 5.72-3.808 9.192c0 3.472 1.352 6.737 3.808 9.192s5.72 3.808 9.192 3.808c3.472 0 6.737-1.352 9.192-3.808s3.808-5.72 3.808-9.192c0-3.472-1.352-6.737-3.808-9.192s-5.72-3.808-9.192-3.808zM16 0v0c8.837 0 16 7.163 16 16s-7.163 16-16 16c-8.837 0-16-7.163-16-16s7.163-16 16-16zM14 22h4v4h-4zM14 6h4v12h-4z"/></svg>
                                    There isn't any content with this filter
                             </div>`;

        /*
         * GET SPECIALS INFO
         ********************************************************************************/
        function GetAttractions(){
            fetch(`${site_url}ajax/functions.php?operation=FrontendLoadAttractions` )
                .then((resp) => resp.json() ) // Transform the data into json
                .then( (data) => {

                    //POPULATE SELECTORS
                    PopulateSelectors( CATEGORY_SELECTOR , data.pins, "category", data.config.label_select_category);
                    // PopulateSelectors( DISTANCE_SELECTOR , data.pins, "distance", 'Select Distance');

                    //Change the label from selector distances
                    const selDistance = DISTANCE_SELECTOR.options[DISTANCE_SELECTOR.selectedIndex];
                    selDistance.innerText = data.config.label_select_distance;

                    //Change the reset button label
                    document.getElementsByClassName('btn reset')[0].innerText = data.config.label_reset;

                    //POPULATE GOOGLE MAPS & ATTRACTIONS QUAD 
                    GetAttractionsMarkup(data.pins);
                    LoadGoogleMaps(data.config, data.pins);
                    matchAttractionWithMarkers();

                    return categoriesData = data;
                });

        }

        function isValidJson(json) {
            try {
                JSON.parse(json);
                return true;
            } catch (e) {
                return false;
            }
        }

        /**************************************************************************************************************************************************************
         * GOOGLE MAPS
         ****************************************************************************************************************************************************************/
        function LoadGoogleMaps(config, pins) {

            const InitializeMap = (lat, long, zoom = 13, styles) => {

                //SET POSITION OF MAP
                let PinPosition = new google.maps.LatLng(lat, long); //set longitude and latitude

                let styles_temp = isValidJson( styles ) ? JSON.parse( styles ) : "";

                // option for google map object
                let myOptions = {
                    zoom: zoom,
                    center: PinPosition,
                    mapTypeId: "roadmap",
                    mapTypeControl: false,
                    styles: styles_temp,
                    scrollwheel: false,
                    disableDoubleClickZoom: false,
                    backgroundColor: '#ffffff',
                    panControl: false,
                    zoomControl: true,
                    zoomControlOptions: {
                        position: google.maps.ControlPosition.RIGHT_CENTER
                    }
                };


                // create new map make sure a DIV with id myMap exist on page
                MAP = new google.maps.Map(GOOGLE_MAPS_WRAPPER, myOptions), //generate map into div id myMap
                    HOTEL_PIN = {
                        id: "hotel",
                        map: MAP,
                        title: config.hotel_name,
                        icon: config.hotel_pin.pin,
                        zIndex: 2,
                        //animation: google.maps.Animation.BOUNCE,
                        position: new google.maps.LatLng(config.hotel_pin.lat, config.hotel_pin.long)
                    };
                // create new marker
                new google.maps.Marker(HOTEL_PIN);

                var hotelMaker = new google.maps.Marker(HOTEL_PIN);

                hoverPinHotel(hotelMaker, MAP);

                pins.forEach( pin => {
                    let marker = loadMarker(pin, MAP, config.regular_pin.normal);
                    GoogleMapsMarkers.push(marker);
                    // this shows the name of each pin when hover
                    //hoverPin(marker, MAP);
                } );

                initMapTypeControl(MAP);

                return $GoogleMaps = MAP;

            };

            const { lat, long } = config.hotel_pin,
                { map_zoom, snazzy} = config;

            InitializeMap(lat, long, map_zoom, snazzy);

        }

        // this function accepts two parameters, first: the markers, second: the target map. It shows the name of each pin when hover
        function hoverPin(pin, map){

            let nameMarkerOnHover = '<div class="nameMarker">' + pin.title + '</div>';

            let infowindow = new google.maps.InfoWindow({
                content: nameMarkerOnHover
            });

            pin.addListener('mouseover', function () {
                infowindow.open(map, pin);
                pin.setIcon(iconHover);
            })

            pin.addListener('mouseout', function () {
                infowindow.close(map, pin);
                pin.setIcon(iconDefault);
            })
        }

        /***
         * Load marker to map
         ***/
        function loadMarker(pin, map_selector, icon) { //writes the marker to the map. pass a javascript object with the markers longitude latitude id and name so that the code can keep track of all the markers.

            let {id, name, lat, long} = pin;
            //console.log(pin);

            var image = {
                url: icon,
                // This marker is 20 pixels wide by 32 pixels high.
                size: new google.maps.Size(30, 45),
                // The origin for this image is (0, 0).
                origin: new google.maps.Point(0, 0),
                // The anchor for this image is the base of the flagpole at (0, 32).
                anchor: new google.maps.Point(0, 45)
            };

            // create new marker
            let marker = new google.maps.Marker({
                id: id,
                map: map_selector,
                title: name,
                icon: icon,
                zIndex: 1,
                position: new google.maps.LatLng(lat, long)
            });

            //marker.addListener('click', event => OpenLightbox(marker) );

            //let nameMarkerOnclick = getAttractionMarkUp(pin);
            let nameMarkerOnHover = getAttractionMarkUpHover(pin);

            let nameMarkerOnHover1 = new google.maps.InfoWindow({
                content: nameMarkerOnHover
            });

            marker.addListener('mouseover', function () {
                nameMarkerOnHover1.open(map_selector, marker);
                marker.setIcon(iconHover);
            })

            marker.addListener('mouseout', function () {
                nameMarkerOnHover1.close(map_selector, marker);
                marker.setIcon(icon);
            })

            marker.addListener('click', event => OpenLightbox(marker) );

            return marker;

        }

        function ExpandContent(event) {
            let el = event.target;

            let getIdBtn = el.getAttribute('id');

            if(getIdBtn === 'js-expand-map'){
                el.textContent = el.textContent === "Expand Map" ? "Contract Map" : "Expand Map";

                // if (targetMap.style.width !== "100%") {
                //     //targetMap.style.cssText = "width: 100%; z-index: 3";
                // } else {
                //     // targetMap.style.removeProperty("width");
                //     // targetMap.style.removeProperty("z-index");
                // }


                if(ATTRACTIONS_CONTENT.classList.contains('map-expanded')){
                    ATTRACTIONS_CONTENT.classList.remove('map-expanded');
                }else{
                    ATTRACTIONS_CONTENT.classList.add('map-expanded');
                }

            }else if(getIdBtn === 'expand--attractions-content'){
                el.textContent = el.textContent === "Expand Attractions" ? "Contract Attractions" : "Expand Attractions";

                if (targetAtractions.style.width !== "100%") {
                    targetAtractions.style.cssText = "width: 100%; z-index: 5";
                    ATTRACTIONS_WRAPPER.classList.add('attractions-expanded');
                } else {
                    targetAtractions.style.removeProperty("width");
                    targetAtractions.style.removeProperty("z-index");
                    ATTRACTIONS_WRAPPER.classList.remove('attractions-expanded');
                }
            }


        }

        function initMapTypeControl(map) {
            if(document.querySelector(".attractions-map__satellite")!==null){
                document.querySelector(".attractions-map__satellite").onclick = function () {
                    document.querySelector(".attractions-map__map").classList.remove('active')
                    this.classList.remove('active')
                    this.classList.add('active')
                    map.setMapTypeId("hybrid");
                };
            }

            if(document.querySelector(".attractions-map__map")!==null){
                document.querySelector(".attractions-map__map").onclick = function () {
                    document.querySelector(".attractions-map__satellite").classList.remove('active')
                    this.classList.remove('active')
                    this.classList.add('active')
                    map.setMapTypeId("roadmap");
                };
            }
        }

        /**************************************************************************************************************************************************************
         * FILTERING DATA
         ****************************************************************************************************************************************************************/
        function FilterData(event){
            event.preventDefault();
            let Filter = GetFormValues(document.querySelector("#attractions-filter"));
            GetFilterData(Filter);

            /*
             * Get values from form
             ********************************************************************************/
            function GetFormValues (el) {

                let elements = el.querySelectorAll("input, select");

                //SETTINGS
                let values = {};

                //GET VALUES
                for (let i = 0; i < elements.length; ++i) {
                    let element = elements[i];
                    let name = element.name;
                    let value = element.value;

                    if (name) {
                        values[name] = value;
                    }
                }

                return values;

            }

        }

        /*
         * Filter Data Based on what the User Selected on the Page Form
         ********************************************************************************/
        function GetFilterData (filter){

            let { attraction_categories, attraction_distance } = filter,
                MapBoundaries = new google.maps.LatLngBounds();

            let [ distance_min, distance_max ] = attraction_distance.split('-');
            distance_min = distance_min ? distance_min : 0;
            distance_max = distance_max ? distance_max : Number.MAX_SAFE_INTEGER;

            // let regexSearch = RegExp(`.*(?=.*${search_property_by_name.split(' ').join(')(?=.*')}).*`,'i');
            let result =  categoriesData.pins.filter( (item) => {
                if( attraction_categories !== "" && item.category !== attraction_categories ) return false;
                // if( search_property_by_name !== "" && !regexSearch.test(item.title) ) return false;
                if( +item.distance < distance_min || +item.distance > distance_max ) return false;
                return true;
            });

            if(result.length !== 0){
                GetAttractionsMarkup(result);
            }else{
                document.getElementById('attractions-wrapper').innerHTML = noContentfilter;
            }

            //CLEAR PINS ON MARKER
            GoogleMapsMarkers.forEach( pin => pin.setMap(null) );
            GoogleMapsMarkers = [];
            //Add Pins on Google Maps
            result.forEach( pin => {
                let marker = loadMarker(pin, $GoogleMaps, categoriesData.config.regular_pin.normal);
                MapBoundaries.extend( marker.getPosition() );
                GoogleMapsMarkers.push(marker);

                // this shows the name of each pin when hover
                hoverPin(marker, MapBoundaries);

            });

            matchAttractionWithMarkers();

            //Fit All pins on Map
            $GoogleMaps.fitBounds(MapBoundaries);

        }


        /**************************************************************************************************************************************************************
         * LIGHTBOX
         ****************************************************************************************************************************************************************/
        const LIGHTBOX_OPENED__CLASS = "opened-lightbox";
        function OpenLightbox (element){
            //Get Pin ID and then info
            let pin_id = typeof element.id === "number" ? element.id : parseInt( element.getAttribute("data-attraction") ),
                PinInfo = categoriesData.pins[pin_id];

            //Get Pin Info
            let markup = getAttractionMarkUp(PinInfo);
            LIGHTBOX_WRAPPER.innerHTML = markup;
            document.querySelector("body").style.overflowY = 'hidden';
            LIGHTBOX__FULL_WRAPPER.classList.add(LIGHTBOX_OPENED__CLASS);
        }

        function CloseLigthbox(event) {
            if(event.keyCode === undefined && !event.target.classList.contains( LIGHTBOX_OPENED__CLASS )  && event.target.id !== LIGHTBOX__CLOSE_BUTTON ) return false;
            LIGHTBOX_WRAPPER.innerHTML = "";
            LIGHTBOX__FULL_WRAPPER.classList.remove(LIGHTBOX_OPENED__CLASS);
            document.querySelector("body").style.overflowY = 'visible';
        }


        /**************************************************************************************************************************************************************
         * FUNCTION CALLING AND EVENT LISTENERS
         ****************************************************************************************************************************************************************/
        GetAttractions();
        document.querySelector("a#js-expand-map").addEventListener("click", event => ExpandContent(event) );
        //document.querySelector("a#expand--attractions-content").addEventListener("click", event => ExpandContent(event) );
        //document.querySelector("#attractions-filter").addEventListener("submit", event => FilterData(event) );
        document.querySelector("#attraction-category").addEventListener("change", event => FilterData(event) );
        document.querySelector("#attraction-distance").addEventListener("change", event => FilterData(event) );

        document.querySelector("#attractions-filter").addEventListener("reset", event => setTimeout( function(){ FilterData(event) }, 0) );
        document.addEventListener('keydown', (event) =>  event.keyCode === 27 ? CloseLigthbox(event)  : false  );
        LIGHTBOX__FULL_WRAPPER.addEventListener('click', (event) =>  CloseLigthbox(event) );
        $LIGHTBOX__CLOSE_BUTTON.addEventListener('click', (event) =>  CloseLigthbox(event) );
    }


})();
